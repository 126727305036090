import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import './selectlocation.sass'

export default function SelectLocation (props) {
  const { city, index, saveLocations, banner, screenValue } = props
  const [checkedKioks, setCheckedKioks] = React.useState([])
  const [checkedLocations, setCheckedLocations] = React.useState([])
  const [selectAll, setselectAll] = React.useState(false)
  const selectLocationValues = {
    index: index,
    id: 0,
    location_ids: [],
    kiosk_ids: []
  }

  React.useEffect(() => {
    if (banner) {
      const BannerCity = banner.cities.find(
        (bannerCity) => bannerCity.id === city.id
      )
      if (BannerCity) {
        selectLocationValues.id = city.id
        setselectAll(true)
      } else {
        selectLocationValues.location_ids = []
        selectLocationValues.kiosk_ids = []
        const selectedLocations = [...checkedLocations]
        const selectedKioks = [...checkedKioks]
        const kiosks = []
        const locations = []
        if (city.locations) {
          for (const location of city.locations) {
            locations.push(location.id)
            if (location.areas) {
              for (const area of location.areas) {
                if (area.kiosks) {
                  for (const kiosk of area.kiosks) {
                    kiosks.push(kiosk.id)
                  }
                }
              }
            }
          }
        }
        banner.locations.forEach((location) => {
          if (locations.includes(location.id)) {
            selectedLocations.push(location.id)
            selectLocationValues.location_ids.push(location.id)
          }
        })
        banner.kiosks.forEach((kiosk) => {
          if (kiosks.includes(kiosk.id)) {
            selectedKioks.push(kiosk.id)
            selectLocationValues.kiosk_ids.push(kiosk.id)
          }
        })
        setCheckedKioks(selectedKioks)
        setCheckedLocations(selectedLocations)
      }
      saveLocations(selectLocationValues)
    }
  }, [banner])

  const validateAllLocationsSelect = () => {
    if (checkedLocations.length - 1 === city.locations.length) {
      setselectAll(true)
      validateParamsToSend(true)
    } else {
      setselectAll(!selectAll)
      validateParamsToSend(!selectAll)
    }
  }

  function validateSelectAll(locationsChecked, selectedKioks) {
    if (locationsChecked.length - 1 === city.locations.length) {
      setselectAll(true)
      validateParamsToSend(true, locationsChecked, selectedKioks)
    } else {
      setselectAll(false)
      validateParamsToSend(false, locationsChecked, selectedKioks)
    }
  }

  const handleSelectLocation = (location) => {
    const currentIndex = checkedLocations.indexOf(location.id)
    const selectedLocations = [...checkedLocations]
    const selectedKioks = [...checkedKioks]
    if (currentIndex === -1) {
      selectedLocations.push(location.id)
      location.areas.forEach((area) => {
        area.kiosks.forEach((kiosk) => {
          if (!selectedKioks.includes(kiosk.id)) selectedKioks.push(kiosk.id)
        })
      })
    } else {
      selectedLocations.splice(currentIndex, 1)
      location.areas.forEach((area) => {
        area.kiosks.forEach((kiosk) => {
          const kioskIndex = selectedKioks.indexOf(kiosk.id)
          selectedKioks.splice(kioskIndex, 1)
        })
      })
    }
    setCheckedLocations(selectedLocations)
    setCheckedKioks(selectedKioks)
    validateSelectAll(selectedLocations, selectedKioks)
  }

  const handleSelectKiosk = (location, kiosk) => () => {
    const currentIndex = checkedKioks.indexOf(kiosk.id)
    const selectedKioks = [...checkedKioks]
    if (currentIndex === -1) selectedKioks.push(kiosk.id)
    else selectedKioks.splice(currentIndex, 1)
    setCheckedKioks(selectedKioks)
    const areasSelected = []
    location.areas.forEach((area) => {
      const kioskSelected = []
      area.kiosks.forEach((kiosk) => {
        if (selectedKioks.includes(kiosk.id)) kioskSelected.push(kiosk.id)
      })
      if (area.kiosks.length === kioskSelected.length)
        areasSelected.push(kioskSelected)
    })
    const locationsChecked = [...checkedLocations]
    const locationIndex = checkedLocations.indexOf(location.id)
    if (location.areas.length === areasSelected.length)
      locationsChecked.push(location.id)
    else if (locationIndex !== -1) locationsChecked.splice(locationIndex, 1)
    setCheckedLocations(locationsChecked)
    validateSelectAll(locationsChecked, selectedKioks)
  }

  function validateParamsToSend(allSelected, locationsChecked, selectedKioks) {
    if (allSelected) selectLocationValues.id = city.id
    else {
      selectLocationValues.location_ids = locationsChecked || []
      selectLocationValues.kiosk_ids = selectedKioks || []
    }
    saveLocations(selectLocationValues)
  }

  const returnItemKey = (index) => {
    return index + Math.random()
  }

  function ValidateToShowKioskByScreenType(location, kiosk, index) {
    const labelId = `checkbox-list-label-${index}`
    if (kiosk.kiosk_type === screenValue) {
      return (
        <ListItem
          key={returnItemKey(kiosk.id)}
          dense
          button
          onClick={handleSelectKiosk(location, kiosk)}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={
                checkedKioks.indexOf(kiosk.id) !== -1 ||
                checkedLocations.indexOf(location.id) !== -1 ||
                selectAll
              }
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={`${kiosk.name}`} />
        </ListItem>
      )
    } else return <Typography key={returnItemKey(kiosk.id)} display="none"></Typography>
  }

  return (
    <div>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <Checkbox
            checked={
              selectAll ||
              (checkedLocations.length === city.locations.length &&
                city.locations.length > 0)
            }
            onClick={(event) => {
              event.stopPropagation()
              validateAllLocationsSelect()
            }}
            indeterminate={
              !selectAll && checkedLocations.length > 0 &&
              checkedLocations.length < city.locations.length
            }
          />

          <Typography component={'span'} className="panel_title">
            {city.name}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="expansion_container">
          {city.locations.map((location) => {
            return (
              <ExpansionPanel
                key={returnItemKey(location.name)}
                className="expansion"
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <Checkbox
                    checked={
                      checkedLocations.indexOf(location.id) !== -1 || selectAll
                    }
                    onClick={(event) => {
                      event.stopPropagation()
                      handleSelectLocation(location)
                    }}
                  />
                  <Typography component={'span'} className="panel_title">
                    {' '}
                    {location.name}{' '}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography
                    component={'span'}
                    className="expansion"
                    color="textSecondary"
                  >
                    <List>
                      {location.areas ? location.areas.map((area) => {
                        return area.kiosks.map((kiosk, index) => {
                          return ValidateToShowKioskByScreenType(
                            location,
                            kiosk,
                            index
                          )
                        })
                          })
                        : null}
                    </List>
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          })}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}
