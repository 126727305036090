// Dependences
import React from 'react'
import moment from 'moment'

// UI components
import { withStyles, Link } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { CURRENCY_CODES, TRANSACTION_STATUS } from '../../constants/Constants'
import { transactionOperation } from '../../utils/utils'

// Styles and assets
import { transactionRowStyles } from './transactionrow.styles'

const TransactionRow = (props) => {
  const { transaction, countryName, onDetail, classes } = props
  const handleDetails = () => {
    onDetail(transaction)
  }

  const operation = transactionOperation(transaction.operation)
  const operationStyle = () => {
    if (transaction.operation === TRANSACTION_STATUS.NOT_PAYED)
      return classes.cellWarn
    if (transaction.operation === TRANSACTION_STATUS.COMPLETED)
      return classes.cellDefault
    return classes.cellError
  }

  const transactionTotal = Number((transaction.total / 100).toFixed(2))
  const countryCurrency = CURRENCY_CODES.find(
    (code) => code.countryName === countryName
  )
  let currencyFormated = new Intl.NumberFormat(
    countryCurrency.languajeCode ? countryCurrency.languajeCode : 'es-CO',
    { style: 'currency', currency: countryCurrency.currency }
  ).format(transactionTotal)
  const currencyUnFormated = `${countryCurrency.currencySymbol}${transactionTotal}`

  if (countryCurrency.countryName === 'Ecuador') {
    if (countryCurrency.languajeCode) {
      const total = transaction.total.toString()
      currencyFormated =
        '$' +
        total.substring(0, total.length - 2) +
        ',' +
        total.substring(total.length - 2)
    }
  }

  return (
    <TableRow>
      <TableCell id="transaction-id" onClick={handleDetails}>
        <Link>{transaction.bank_transaction_id}</Link>
      </TableCell>
      <TableCell>{transaction.customer}</TableCell>
      <TableCell>
        {moment(transaction.created_at).format('D/MM/YYYY hh:mm a')}
      </TableCell>
      <TableCell>{`${transaction.duration} seg`}</TableCell>
      <TableCell className={operationStyle()}>{operation}</TableCell>
      <TableCell>{transaction.franchise}</TableCell>
      <TableCell>
        {countryCurrency.languajeCode ? currencyFormated : currencyUnFormated}
      </TableCell>
      {/* <TableCell>{transaction.items[0].description}...</TableCell> */}
    </TableRow>
  )
}

export default withStyles(transactionRowStyles)(TransactionRow)
