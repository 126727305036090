/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doFetchCities, doFetchCitiesByCountryId } from '../../actions/city'
import { getLoginToken } from '../../selectors/user'
import { companyId } from '../../selectors/company'

import { doClearRequestStatus } from '../../actions/clearStatus'
import { getError, getMessage } from '../../selectors/city'
import {
  IN_SERVICE,
  OUT_OF_SERVICE,
  WARNING,
  ALL_STATUSES
} from '../../constants/Constants'
import { sortBy } from 'lodash'

// Utils
import { groupedKiosksCountByStatus } from '../../utils/utils.js'

// Custom components
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import KioskCountCard from '../../components/KioskCountCard/KioskCountCard'
import CityRow from '../../components/CityRow/CityRow'
import CircularLoading from '../../components/Loading/CircularLoading'
import MySnackbar from '../../components/MySnackbar/MySnackbar'

// UI Components
import { withStyles } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from '@material-ui/icons/Search'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

// Styles and assets
import './countrystatus.sass'
import { countryStatusStyles } from './countrystatus.styles'
import allKiosks from './../../assets/images/icon-all.svg'
import statusWithService from './../../assets/images/icon-service.svg'
import statusWarning from './../../assets/images/icon-warning.svg'
import statusWithoutService from './../../assets/images/icon-without.svg'

const SORTS = {
  NONE: (list) => sortBy(list, 'name'),
  LOCATIONS_COUNT: (list) => sortBy(list, 'locations'),
  TOTAL_COUNT: (list) => sortBy(list, 'total_kiosks'),
  IN_SERVICE_COUNT: (list) => sortBy(list, 'in_service'),
  OUT_OF_SERVICE_COUNT: (list) => sortBy(list, 'out_of_service'),
  WARNING_COUNT: (list) => sortBy(list, 'warning')
}

class CountryStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      rowsPerPage: 10,
      currentPage: 0,
      order: 'asc',
      orderBy: 'NONE'
    }
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  onChangePage = (event, page) => {
    this.setState({ currentPage: page })
  }

  onChangeQuery = (event) => {
    this.setState({ query: event.target.value })
  }

  onListAllKiosks = () => {
    this.showKioskList(ALL_STATUSES)
  }

  onListInServiceKiosks = () => {
    this.showKioskList(IN_SERVICE)
  }

  onListOutOfServiceKiosks = () => {
    this.showKioskList(OUT_OF_SERVICE)
  }

  onListInWarningKiosks = () => {
    this.showKioskList(WARNING)
  }

  showKioskList = (status) => {
    const country = this.props.location.state.country
    this.props.history.push('/kiosks-status', {
      country: country,
      status: status
    })
  }

  componentDidMount() {
    this.props.doClearRequestStatus()
    this.fetchCities()
  }

  fetchCities = () => {
    const { user, companyId, location } = this.props
    this.props.doFetchCitiesByCountryId({
      data: {
        countryId: location.state.country.id,
        user: user,
        companyId: companyId
      }
    })
  }

  showSetttings = () => {
    const country = this.props.location.state.country
    this.props.history.push('/settings', { country: country })
  }

  onShowCityDetail = (city) => {
    const country = this.props.location.state.country
    this.props.history.push('/city-status', {
      data: { city: city, country: country }
    })
  }

  handleRequestSort = (property) => () => {
    const orderBy = property
    let order = 'desc'
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    this.setState({ order, orderBy })
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  render() {
    const { order, orderBy } = this.state
    const country = this.props.location.state.country
    const count = groupedKiosksCountByStatus(country.kiosk_status_count)
    const { in_service, out_of_service, warning } = count
    const { loading, cities } = this.props.city
    let listItems
    let filteredCities
    if (cities) {
      const currentPageIndex = this.state.currentPage * this.state.rowsPerPage
      const pageEndIndex = currentPageIndex + this.state.rowsPerPage
      filteredCities = cities.filter((city) => {
        if (this.state.query.length > 0) {
          return city.name
            .toLowerCase()
            .includes(this.state.query.toLowerCase())
        }
        return true
      })
      const sortedCities = SORTS[orderBy](filteredCities)
      const reversedCities =
        order === 'desc' ? sortedCities.reverse() : sortedCities
      listItems = reversedCities
        .slice(currentPageIndex, pageEndIndex)
        .map((city) => {
          const count = groupedKiosksCountByStatus(
            city.kiosk_status_count
          )
          city.in_service = count.in_service
          city.out_of_service = count.out_of_service
          city.warning = count.warning
          return <CityRow key={city.id} city={city} />
        })
    }

    const links = [
      {
        title: 'Inicio',
        onClick: () => {
          this.props.history.goBack()
        }
      }
    ]
    return (
      <div className="page-status">
        <MySnackbar
          open={this.props.error}
          variant={this.props.error ? 'error' : 'default'}
          onClose={this.handleCloseSnackBar}
          message={this.props.message || ''}
        />
        <Header links={links} currentPage={country.name} />
        <Sidebar level={1} country={country} />
        <div className={this.props.classes.content}>
          {loading ? (
            <CircularLoading isLoading={loading} />
          ) : (
            <div className="kiosk-status-container">
              <div className="kiosks-status">
                <KioskCountCard
                  icon={allKiosks}
                  title={'Total kioscos'}
                  count={country.total_kiosks}
                  onClick={this.onListAllKiosks}
                />
                <KioskCountCard
                  icon={statusWithService}
                  title={'En servicio'}
                  count={in_service}
                  onClick={this.onListInServiceKiosks}
                />
                <KioskCountCard
                  icon={statusWithoutService}
                  title={'Sin servicio'}
                  count={out_of_service}
                  onClick={this.onListOutOfServiceKiosks}
                />
                <KioskCountCard
                  icon={statusWarning}
                  title={'En advertencia'}
                  count={warning}
                  onClick={this.onListInWarningKiosks}
                />
              </div>
              <div className="list-kiosks">
                <Input
                  className={this.props.classes.findField}
                  placeholder="Buscar por nombre de ciudad"
                  value={this.state.query}
                  onChange={this.onChangeQuery}
                  disableUnderline={true}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                />
                <Table padding="dense">
                  <TableHead>
                    <TableRow>
                      <TableCell>Ciudad</TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'LOCATIONS_COUNT'}
                          direction={order}
                          onClick={this.handleRequestSort('LOCATIONS_COUNT')}
                        >
                          Ubicaciones
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'TOTAL_COUNT'}
                          direction={order}
                          onClick={this.handleRequestSort('TOTAL_COUNT')}
                        >
                          Kioscos
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'IN_SERVICE_COUNT'}
                          direction={order}
                          onClick={this.handleRequestSort('IN_SERVICE_COUNT')}
                        >
                          En servicio
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'OUT_OF_SERVICE_COUNT'}
                          direction={order}
                          onClick={this.handleRequestSort(
                            'OUT_OF_SERVICE_COUNT'
                          )}
                        >
                          Sin servicio
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'WARNING_COUNT'}
                          direction={order}
                          onClick={this.handleRequestSort('WARNING_COUNT')}
                        >
                          En advertencia
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{listItems}</TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={(filteredCities && filteredCities.length) || 0}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.currentPage}
                  onChangePage={this.onChangePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                  labelRowsPerPage="Filas por página"
                  backIconButtonProps={{
                    'aria-label': 'Anterior'
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Siguiente'
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getLoginToken(state),
    companyId: companyId(state),
    city: state.city,
    error: getError(state),
    message: getMessage(state)
  }
}

const mapDispatchToProps = {
  doFetchCities,
  doFetchCitiesByCountryId,
  doClearRequestStatus
}

export default withStyles(countryStatusStyles)(
  connect(mapStateToProps, mapDispatchToProps)(CountryStatus)
)
