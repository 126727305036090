/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doFetchCities } from '../../actions/city'
import { doFetchKiosksInfo } from '../../actions/kiosk'
import { getLoginToken } from '../../selectors/user'
import { companyId } from '../../selectors/company'
import { doClearRequestStatus } from '../../actions/clearStatus'
import { getError, getMessage } from '../../selectors/city'
import {
  IN_SERVICE,
  OUT_OF_SERVICE,
  WARNING,
  ALL_STATUSES
} from '../../constants/Constants'
import { sortBy } from 'lodash'

// Utils
import { kioskType } from '../../utils/utils'

// Custom components
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import KioskStatusRow from '../../components/KioskStatusRow/KioskStatusRow'
import CircularLoading from '../../components/Loading/CircularLoading'
import MySnackbar from '../../components/MySnackbar/MySnackbar'

// UI Components
import { withStyles, FormControl, Select, MenuItem } from '@material-ui/core'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from '@material-ui/icons/Search'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

// Styles and assets
import './kiosksstatus.sass'
import { kioksStatusStyles } from './kiosksstatus.styles'

const SORTS = {
  NONE: (list) => sortBy(list, 'name'),
  CITY: (list) => sortBy(list, 'city_name'),
  LOCATION: (list) => sortBy(list, 'location_name'),
  AREA: (list) => sortBy(list, 'area_name'),
  STATUS: (list) => sortBy(list, 'status')
}

const KIOSK_STATUSES = [
  { id: OUT_OF_SERVICE, name: 'Sin servicio' },
  { id: IN_SERVICE, name: 'En servicio' },
  { id: WARNING, name: 'En advertencia' }
]

class KiosksStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCity: null,
      selectedLocation: null,
      selectedKioskStatus: ALL_STATUSES,
      query: '',
      rowsPerPage: 10,
      currentPage: 0,
      order: 'asc',
      orderBy: 'NONE'
    }
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  onChangePage = (event, page) => {
    this.setState({ currentPage: page })
  }

  onChangeQuery = (event) => {
    this.setState({ query: event.target.value })
  }

  onListAllKiosks = () => {
    this.showKioskList(ALL_STATUSES)
  }

  onListInServiceKiosks = () => {
    this.showKioskList(IN_SERVICE)
  }

  onListOutOfServiceKiosks = () => {
    this.showKioskList(OUT_OF_SERVICE)
  }

  onListInWarningKiosks = () => {
    this.showKioskList(WARNING)
  }

  kiosksByStatus = (status) => {
    try {
      const { cities } = this.props.city
      var kiosks = []
      cities.forEach((city) => {
        city.locations.forEach((location) => {
          location.areas.forEach((area) => {
            area.kiosks.forEach((kiosk) => {
              kiosk.city_name = city.name
              kiosk.location_name = location.name
              kiosk.area_name = area.name
              kiosk.type = kioskType(kiosk.kiosk_type)
              if (status === ALL_STATUSES) {
                kiosks.push(kiosk)
              } else if (kiosk.status === status) {
                kiosks.push(kiosk)
              }
            })
          })
        })
      })
      return kiosks
    } catch (error) {
      return []
    }
  }

  componentDidMount() {
    this.props.doClearRequestStatus()
    this.fetchCities()
  }

  fetchCities = () => {
    const { user, companyId, location } = this.props
    this.props.doFetchCities({
      data: {
        countryId: location.state.country.id,
        user: user,
        companyId: companyId
      }
    })
  }

  fetchKiosksInfo = () => {
    const { user, companyId } = this.props
    this.props.doFetchKiosksInfo({
      data: {
        user: user,
        companyId: companyId
      }
    })
  }

  showSetttings = () => {
    const country = this.props.location.state.country
    this.props.history.push('/settings', { country: country })
  }

  onShowLocationDetail = (location) => {
    const { country } = this.props.location.state
    this.props.history.push('/kiosk-by-location', {
      data: { location: location, city: this.selectedCity(), country: country }
    })
  }

  onShowKioskDetail = (kiosk) => {
    const { country } = this.props.location.state
    this.props.history.push('/kiosk-detail', {
      data: {
        location: this.kioskLocation(kiosk.city_name, kiosk.location_name),
        city: this.kioskCity(kiosk.city_name),
        country: country,
        kiosk: kiosk
      }
    })
  }

  handleRequestSort = (property) => () => {
    const orderBy = property
    let order = 'desc'
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    this.setState({ order, orderBy })
  }

  handleCityChange = (event) => {
    const selectedCity = event.target.value === 0 ? null : event.target.value
    this.setState({ selectedCity: selectedCity, selectedLocation: null })
  }

  handleLocationChange = (event) => {
    const selectedLocation =
      event.target.value === 0 ? null : event.target.value
    this.setState({ selectedLocation: selectedLocation })
  }

  handleKioskStatusChange = (event) => {
    const status = event.target.value
    this.setState({ selectedKioskStatus: status })
  }

  selectedCity = () => {
    if (!this.state.selectedCity) {
      return this.props.city.cities[0]
    } else {
      const selectedCity = this.props.city.cities.filter((city) => {
        return city.id === this.state.selectedCity
      })[0]
      return selectedCity
    }
  }

  selectedLocation = () => {
    if (!this.state.selectedLocation) {
      return this.props.city.cities[0].locations[0]
    } else {
      const selectedLocation = this.selectedCity().locations.filter(
        (location) => {
          return location.id === this.state.selectedLocation
        }
      )[0]
      return selectedLocation
    }
  }

  kioskCity = (name) => {
    const result = this.props.city.cities.find(city => city.name === name)
    if (result) return result
    else return this.props.city.cities[0]
  }

  kioskLocation = (cityName, name) => {
    const city = this.kioskCity(cityName)
    const result = city.locations.find(location => location.name === name)
    if (result) return result
    else return this.props.city.cities[0].locations[0]
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  render() {
    const {
      order,
      orderBy,
      selectedKioskStatus,
      selectedLocation,
      selectedCity
    } = this.state
    const { country, status } = this.props.location.state
    const { loading, cities } = this.props.city
    let filteredKiosks
    let listCities
    let listLocations
    let listKiosks
    if (cities) {
      listCities = cities.map((city) => {
        return (
          <MenuItem value={city.id} key={city.id}>
            {city.name}
          </MenuItem>
        )
      })
      if (selectedCity) {
        listLocations = this.selectedCity().locations.map((location) => {
          return (
            <MenuItem value={location.id} key={location.id}>
              {location.name}
            </MenuItem>
          )
        })
      }
      let kiosks = this.kiosksByStatus(
        status === undefined ? selectedKioskStatus : status
      )
      filteredKiosks = kiosks.filter((kiosk) => {
        var shouldAddKiosk = true
        if (selectedCity) {
          shouldAddKiosk =
            kiosk.city_name.toLowerCase() ===
            this.selectedCity().name.toLowerCase()
        }
        if (selectedLocation) {
          shouldAddKiosk =
            kiosk.location_name.toLowerCase() ===
            this.selectedLocation().name.toLowerCase()
        }
        if (this.state.query.length > 0) {
          return (
            kiosk.name.toLowerCase().includes(this.state.query.toLowerCase()) &&
            shouldAddKiosk
          )
        }
        return shouldAddKiosk
      })

      const currentPageIndex = this.state.currentPage * this.state.rowsPerPage
      const pageEndIndex = currentPageIndex + this.state.rowsPerPage
      const sortedKiosks = SORTS[orderBy](filteredKiosks)
      const reversedKiosks =
        order === 'desc' ? sortedKiosks.reverse() : sortedKiosks
      listKiosks = reversedKiosks
        .slice(currentPageIndex, pageEndIndex)
        .map((kiosk) => {
          return (
            <KioskStatusRow
              key={kiosk.id}
              kiosk={kiosk}
              countryName={country.name}
              shouldShowStatus={status === undefined}
              onDetail={this.onShowKioskDetail}
            />
          )
        })
    }

    const links = [
      {
        title: 'Inicio',
        onClick: () => {
          this.props.history.goBack()
        }
      }
    ]
    return (
      <div className="page-status">
        <MySnackbar
          open={this.props.error}
          variant={this.props.error ? 'error' : 'default'}
          onClose={this.handleCloseSnackBar}
          message={this.props.message || ''}
        />
        <Header links={links} currentPage={country.name} />
        <Sidebar level={1} country={country} />
        <div className={this.props.classes.content}>
          {loading ? (
            <CircularLoading isLoading={loading} />
          ) : (
            <div className="page_container">
              <div className="switch_city_container">
                <Typography className={this.props.classes.textCities}>
                  Ciudad:
                </Typography>
                <FormControl
                  variant="outlined"
                  className={this.props.classes.chooseCity}
                >
                  <Select
                    input={
                      <OutlinedInput name="selectedCity" id="city-select" />
                    }
                    value={this.state.selectedCity || 0}
                    onChange={this.handleCityChange}
                  >
                    <MenuItem value={0} key={0}>
                      Todas las ciudades
                    </MenuItem>
                    {listCities}
                  </Select>
                </FormControl>
                <Typography className={this.props.classes.textCities}>
                  Ubicación:
                </Typography>
                <FormControl
                  variant="outlined"
                  className={this.props.classes.chooseCity}
                >
                  <Select
                    input={
                      <OutlinedInput
                        id="location-select"
                        disabled={!this.state.selectedCity}
                      />
                    }
                    value={this.state.selectedLocation || 0}
                    onChange={this.handleLocationChange}
                  >
                    <MenuItem value={0} key={0}>
                      Ubicaciones
                    </MenuItem>
                    {listLocations}
                  </Select>
                </FormControl>
                {status === undefined && (
                  <div className={this.props.classes.chooseCity}>
                    <Typography className={this.props.classes.textCities}>
                      Estado del kiosco:
                    </Typography>
                    <FormControl
                      variant="outlined"
                      className={this.props.classes.chooseCity}
                    >
                      <Select
                        input={<OutlinedInput id="kiosk-select" />}
                        value={this.state.selectedKioskStatus}
                        onChange={this.handleKioskStatusChange}
                      >
                        <MenuItem value={ALL_STATUSES} key={ALL_STATUSES}>
                          Todos los estados
                        </MenuItem>
                        {KIOSK_STATUSES.map((status) => {
                          return (
                            <MenuItem value={status.id} key={status.id}>
                              {status.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
              <div className="list-kiosks">
                <Input
                  id="kiosk-search"
                  className={this.props.classes.findField}
                  placeholder="Buscar por nombre de kiosk"
                  value={this.state.query}
                  onChange={this.onChangeQuery}
                  disableUnderline={true}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                />
                <Table padding="dense">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'NONE'}
                          direction={order}
                          onClick={this.handleRequestSort('NONE')}
                        >
                          Kiosco
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'CITY'}
                          direction={order}
                          onClick={this.handleRequestSort('CITY')}
                        >
                          Ciudad
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'LOCATION'}
                          direction={order}
                          onClick={this.handleRequestSort('LOCATION')}
                        >
                          Ubicación
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'AREA'}
                          direction={order}
                          onClick={this.handleRequestSort('AREA')}
                        >
                          Área
                        </TableSortLabel>
                      </TableCell>
                      {status === undefined && (
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'STATUS'}
                            direction={order}
                            onClick={this.handleRequestSort('STATUS')}
                          >
                            Estado
                          </TableSortLabel>
                        </TableCell>
                      )}
                      <TableCell>Consulta de estado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{listKiosks}</TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={(filteredKiosks && filteredKiosks.length) || 0}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.currentPage}
                  onChangePage={this.onChangePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                  labelRowsPerPage="Filas por página"
                  backIconButtonProps={{
                    'aria-label': 'Anterior'
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Siguiente'
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getLoginToken(state),
    companyId: companyId(state),
    city: state.city,
    error: getError(state),
    message: getMessage(state),
  }
}

const mapDispatchToProps = {
  doFetchCities,
  doClearRequestStatus,
  doFetchKiosksInfo
}

export default withStyles(kioksStatusStyles)(
  connect(mapStateToProps, mapDispatchToProps)(KiosksStatus)
)
