import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import BannerDetailDialog from './BannerDetail'

import './bannertable.sass'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import FilterRolePermissions from '../../utils/FilterRolePermissions'

const BannerTable = (props) => {
  const {
    tableData,
    cities,
    section,
    screenValue,
    onPick,
    onSave,
    deleteBanner,
    userRole,
    screenText
  } = props

  const onPickImage = (file, image, section, index, id) => {
    onPick(file, image, section, index, id)
  }
  const onSaveBanner = (body) => {
    onSave(body)
  }
  const onDeleteBanner = (event, id) => {
    if (event) {
      deleteBanner(id)
    }
  }

  function viewYouTube(url, event) {
    if (event) window.open(url)
  }

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx)
  }

  function screen(screen) { return screen === screenValue }

  const videoThumbnail = (url) => {
    try {
      let startIndex = url.search('v=')
      let thumbnailUrl = 'https://img.youtube.com/vi/'
      let start = 2
      let countToVideoId
      if (startIndex !== -1) {
        countToVideoId = range(1, 13)
      } else {
        startIndex = url.search('embed')
        countToVideoId = range(1, 17)
        start = 6
      }
      countToVideoId.forEach((count) => {
        if (count > start) thumbnailUrl += url[startIndex]
        startIndex++
      })
      thumbnailUrl += '/hqdefault.jpg'
      return thumbnailUrl
    } catch (error) {
      return ''
    }
  }

  function ImgOrVideo(banner) {
    if (!banner.video_url && screen(banner.screen_type)) {
      return (
        <img
          className="video_thumbnail image_banner"
          src={banner.image_url}
          alt=""
        />
      )
    } else {
      return (
        <Button
          onClick={(event) => viewYouTube(banner.video_url, event)}
          color="primary"
          autoFocus
          fullWidth={false}
        >
          <img
            className="video_thumbnail"
            src={videoThumbnail(banner.video_url)}
            alt=""
          />
        </Button>
      )
    }
  }

  function TableCells () {
    return tableData.map((banner) => {
      return (
        <TableRow key={banner.id}>
          <TableCell className="banner_cell" component="th" scope="row">
            {ImgOrVideo(banner)}
          </TableCell>
          <TableCell className="banner_cell" align="left">
            {banner.cities.length || banner.locations.length || 0}
          </TableCell>
          <TableCell className="banner_cell" align="left">
            {banner.kiosks.length || 0}
          </TableCell>
          <TableCell className="banner_cell" align="left">
            <div className="actions_container">
              {(FilterRolePermissions('promotion', 'update', userRole)) && (
                <BannerDetailDialog
                  cities={cities}
                  screenValue={screenValue}
                  onSave={onSaveBanner}
                  section={section}
                  onPickFile={onPickImage}
                  isEdit={true}
                  banner={banner}
                  screenType={screenText}
                />
              )}
              {(FilterRolePermissions('promotion', 'delete', userRole)) && (
                <ConfirmDialog
                  title="¿Desea eliminar el banner?"
                  onDelete={(event) => onDeleteBanner(event, banner.id)}
                />
              )}
            </div>
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell className="banner_cell" align="left">
            Ubicaciones
          </TableCell>
          <TableCell className="banner_cell" align="left">
            Kioskos
          </TableCell>
          <TableCell className="banner_cell" align="left">
            Acciones
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableCells></TableCells>
      </TableBody>
    </Table>
  )
}

export default BannerTable
