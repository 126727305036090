// Dependences
import React from 'react'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

// Styles
import './kioskcountcard.sass'

const KioskCountCard = props => {
  const { icon, title, count, onClick } = props
  return (
    <div className="kiosk-status-card" onClick={onClick}>
      <div className="kiosk-status__card-icon">
        <img alt='' src={icon} />
      </div>
      <div className="kiosk-status__card-title">
        <span className="text-total">{title}</span>
        <span className="kiosk-status__card-number">{count}</span>
      </div>
      <div className="kiosk-status__card-indicator">
        <span><KeyboardArrowRight/></span>
      </div>
    </div>
  )
}

export default KioskCountCard
