import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DialogTitle from '@material-ui/core/DialogTitle'
import { CURRENCY_CODES, ITEM_TYPES } from '../../constants/Constants'

import './transactiondetail.sass'

function TransactionTable({ countryName, items, customer }) {
  function currencyFormat(totalInCents) {
    const transactionTotal = Number((totalInCents / 100).toFixed(2))
    const countryCurrency = CURRENCY_CODES.find(
      (code) => code.countryName === countryName
    )
    if (countryCurrency && countryCurrency.languajeCode) {
      let currencyFormated = new Intl.NumberFormat(
        countryCurrency.languajeCode ? countryCurrency.languajeCode : 'es-CO',
        { style: 'currency', currency: countryCurrency.currency }
      ).format(transactionTotal)

      const currencyUnFormated = `${countryCurrency.currencySymbol}${transactionTotal}`

      if (countryCurrency.countryName === 'Ecuador') {
          const total = totalInCents.toString()
          currencyFormated =
            '$' +
            total.substring(0, total.length - 2) +
            ',' +
            total.substring(total.length - 2)
      }
      return countryCurrency.languajeCode
        ? currencyFormated
        : currencyUnFormated
    }
  }

  function itemType(type) {
    switch (type) {
      case ITEM_TYPES.TICKET:
        return 'Entrada'
      case ITEM_TYPES.CONCESSIONS:
        return 'Confitería'
      case ITEM_TYPES.BOOKING_FEE:
        return 'Cargo por Servicio'
      default:
        return 'Not Defined'
    }
  }

  return (
    <Paper elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="detailRow" align="left">
              {' '}
              Cliente{' '}
            </TableCell>
            <TableCell className="detailRow" align="center">
              {' '}
              Tipo{' '}
            </TableCell>
            <TableCell className="detailRow" align="center">
              {' '}
              Descripción{' '}
            </TableCell>
            <TableCell className="detailRow" align="center">
              {' '}
              Cantidad{' '}
            </TableCell>
            <TableCell className="detailRow" align="right">
              {' '}
              Precio{' '}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={`${item.purchase_id}-${item.id}`}>
              <TableCell
                className="detailRow"
                align="left"
                component="th"
                scope="row"
              >
                {' '}
                {customer}{' '}
              </TableCell>
              <TableCell className="detailRow" align="center">
                {itemType(item.item_type)}{' '}
              </TableCell>
              <TableCell className="detailRow" align="center">
                {item.description}
              </TableCell>
              <TableCell className="detailRow" align="center">
                {item.quantity}
              </TableCell>
              <TableCell className="detailRow" align="right">
                {currencyFormat(item.price || '0')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

TransactionTable.propTypes = {
  countryName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(Object).isRequired,
  customer: PropTypes.string.isRequired
}

export default function TransactionDetail({
  open,
  transaction,
  onCloseDialog,
  countryName
}) {
  const handleClose = () => {
    onCloseDialog(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Detalle de la transacción</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TransactionTable
              customer={transaction.customer}
              countryName={countryName}
              items={transaction.items}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="close-transaction-dialog" onClick={handleClose} color="primary" autoFocus>
            ACEPTAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

TransactionDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  transaction: PropTypes.object.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  countryName: PropTypes.string.isRequired
}
